import React, { useState } from "react";
// import Video3 from "./../videos/video.mp4";
// import HeroSection from "../components/heroSection";
// import InfoSection from "../components/infoSection";
import { NavbarInfra } from "../components/navbar/indexInfra";
import { SidebarI } from "../components/sidebar/indexI";

import Image1 from "../images/dsl.jpg";
// import Image2 from "../images/city.svg";
// import Image3 from "../images/message.svg";
// import Image4 from "../images/network.svg";
// import Image5 from "../images/partners1.jpg";
// import Image6 from "../images/partners2.jpg";
// import Image7 from "../images/busilogo.gif";
import InfoSectionLight from "../components/infoSectionLight";
import Footer from "../components/footer";


const ServicePage6 = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);

  };

  return (
    <>
    <SidebarI isOpen={isOpen} toggle={toggle} />
    <NavbarInfra toggle={toggle} />
    {/* <HeroSection videoId={Video3}
      textH="Infrastructure Solutions"
      textP="With our expertise  in Structured Cabling, Enterprise Network
             and Physical Security for Smart City, We provides you the best solutions"
    /> */}
    <InfoSectionLight
        image={Image1}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="Title 1"
        text="NTC Digital is a telecom company that specializes in providing innovative digital communication solutions. Their services include high-speed internet, reliable telephone services, and advanced networking solutions tailored for both residential and business clients. With a commitment to quality and customer satisfaction, NTC Digital leverages cutting-edge technology to ensure robust connectivity and seamless digital experiences. The company focuses on delivering reliable and efficient telecom services to enhance communication and connectivity in the modern digital age."
        // textA="In addition, the pressure from adjacent industries industries like media has marginalized industry profits and minimized improvement in its value chain."
        textB="Most of all the industry is pretty much operating under the old business model and organization structures. While a lot of work may have been done at a strategic level the industry has struggled to execute the strategy within its business operations."
        // textC="We have established NTC Digital to address this challenge and have the vision and the competence to establish new generation of telecommunication service provider, enterprise and public sector."
        // subtitle1="Administration of servers :Windows/Unix/Solaris/AIX/Linux"
        // text1="OS Administration, Patch Maintenance and Monitoring
        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // // text1B="Phone: +966 11 261 5060"
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />


<InfoSectionLight
        image={Image1}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="Title 2"
        text="NTC Digital is a telecom company that specializes in providing innovative digital communication solutions. Their services include high-speed internet, reliable telephone services, and advanced networking solutions tailored for both residential and business clients. With a commitment to quality and customer satisfaction, NTC Digital leverages cutting-edge technology to ensure robust connectivity and seamless digital experiences. The company focuses on delivering reliable and efficient telecom services to enhance communication and connectivity in the modern digital age."
        // textA="In addition, the pressure from adjacent industries industries like media has marginalized industry profits and minimized improvement in its value chain."
        textB="Most of all the industry is pretty much operating under the old business model and organization structures. While a lot of work may have been done at a strategic level the industry has struggled to execute the strategy within its business operations."
        // textC="We have established NTC Digital to address this challenge and have the vision and the competence to establish new generation of telecommunication service provider, enterprise and public sector."
        // subtitle1="Administration of servers :Windows/Unix/Solaris/AIX/Linux"
        // text1="OS Administration, Patch Maintenance and Monitoring
        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // // text1B="Phone: +966 11 261 5060"
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />

<InfoSectionLight
        image={Image1}
        // id="Contact"
        // subtitle="Duis et lectus accumsan"
        title="Title 3"
        text="NTC Digital is a telecom company that specializes in providing innovative digital communication solutions. Their services include high-speed internet, reliable telephone services, and advanced networking solutions tailored for both residential and business clients. With a commitment to quality and customer satisfaction, NTC Digital leverages cutting-edge technology to ensure robust connectivity and seamless digital experiences. The company focuses on delivering reliable and efficient telecom services to enhance communication and connectivity in the modern digital age."
        // textA="In addition, the pressure from adjacent industries industries like media has marginalized industry profits and minimized improvement in its value chain."
        textB="Most of all the industry is pretty much operating under the old business model and organization structures. While a lot of work may have been done at a strategic level the industry has struggled to execute the strategy within its business operations."
        // textC="We have established NTC Digital to address this challenge and have the vision and the competence to establish new generation of telecommunication service provider, enterprise and public sector."
        // subtitle1="Administration of servers :Windows/Unix/Solaris/AIX/Linux"
        // text1="OS Administration, Patch Maintenance and Monitoring
        //        Upgrades/Updates/Migrations"
        // text1A="Level 1/Level 2 and Level 3 Skill Support"
        // // text1B="Phone: +966 11 261 5060"
        // subtitle2="Network and IT Security Operations"
        // text2="LAN/WAN Administration"
        // text2A="Security Operation Support/ Firewall Administration"
        // text2B="Policy Design, Implementation and administration"
        // subtitle3="Assessment and Scope"
        // text3="The success of a Data Center design or relocation is achieved with clearly defined objectives
        //        and requirements for work, resources, products, schedules and cost."
        // subtitle4="Co-Locations"
        // text4="NTC offers a complete range of Engineering and Implementation Solutions that lower you Data
        //        Center design cost. "
        // subtitle5="Redesign & Relocations"
        // text5="As your technology needs change, NTC consultants facilitate a cost-effective redesign of your
        //        Data Center."
        // subtitle6="Consolidation"
        // text6="We provide consolidation planning services using industry-standard best practices that maximize
        //        efficiencies and dramatically reduce Data Center real estate costs."
        // btnText="Read more"
      />

    <Footer />
  </>
);
};

export default ServicePage6;