import React, { useState, useEffect } from "react";
import Logo from "./ntc big logo.png";
import { FaBars } from "react-icons/fa";
import {   useHistory } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import {   Link } from 'react-router-dom';
import './b.css';
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  // NavLinks,
  NavLink,
  NavLin,
  NavBtn,
  // NavBtnLink,
  // A,
} from "./NavbarElements";
import "./../../App.css";

  export const NavbarBusiness = ({ toggle }) => {
    // const path = useLocation().pathname;
    // const location = path.split('/')[1];
    const history = useHistory();
    const scroller = Scroll.scroller;

  const [scrollNav, setScrollNav] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
      window.addEventListener('scroll', changeNav);      
  }, []);

  const goToHomeAndScrollAbout = async () => {
    await closeMobile();
    await history.push('/');
    await scroller.scrollTo('about', {
      duration: 400,
      delay: 10,
      smooth: true,
      // offset: -10,
    });
  };  

  const goToHomeAndScrollPartner = async () => {
    await closeMobile();
    await history.push('/');
    await scroller.scrollTo('discover', {
      duration: 400,
      delay: 10,
      smooth: true,
      // offset: 40,
    });
  };  

  // const goToHomeAndScrollService = async () => {
  //   await closeMobile();
  //   await history.push('/');
  //   await scroller.scrollTo('services', {
  //     duration: 400,
  //     delay: 10,
  //     smooth: true,
  //     offset: 350,
  //   });
  // };  

  const goToHomeAndScrollContact = async () => {
    await closeMobile();
    await history.push('/');
    await scroller.scrollTo('Contact', {
      duration: 400,
      delay: 10,
      smooth: true,
      offset: 20,
    });
  };  


  // const goToBusi = async () => {
  //   await closeMobile();
  //   await history.push('/busi');
  // };  

  // const goToInfra = async () => {
  //   await closeMobile();
  //   await history.push('/infra');
  // };


  const closeMobile = () => {};


  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo >
            {/* <h1 className="logo-text">NTC</h1> */}
            <NavLink to="/home">
            <img className="nav-logo-img" src={Logo} alt="logo"/>
            </NavLink>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>

            <NavItem>
              <NavLink to="/home" activeClassName="active" onClick={goToHomeAndScrollAbout}>About</NavLink>
            </NavItem>
            <NavItem >

              {/* <NavLink to="/busi" activeStyle >Business Solutions</NavLink> */}

              <NavLin>
                <div className="dropdown">
                  <a className="btn btn-body dropdown-toggle" href="/busi" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    Business Solutions
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li><Link className="dropdown-item" to="/dc">◓ DC and DR Solutions</Link></li>
                    <li><Link className="dropdown-item" to="/evs">◓ Enterprise Voice Solutions</Link></li>
                    <li><Link className="dropdown-item" to="/cloud_solution">◓ Clouding Solutions</Link></li>
                    <li><Link className="dropdown-item" to="/iot">◓ Internet of Things</Link></li>
                    <li><Link className="dropdown-item" to="/smart_home">◓ AI Enabled Smart Home Solutions</Link></li>
                  </ul>
                </div>
              </NavLin>
            </NavItem>
            <NavItem>

              {/* <NavLink to="/infra" activeStyle >Infrastructure Solutions</NavLink> */}

              <NavLin>
              <div className="dropdown">
                  <a className="btn btn-body dropdown-toggle" href="/infra" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                   Infrastructure Solutions
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li><Link className="dropdown-item" to="/ens">◓ Enterprise Network Solutions</Link></li>
                    <li><Link className="dropdown-item" to="/SmartCity">◓ Physical Security and Smart City</Link></li>
                    <li><Link className="dropdown-item" to="/Cabling">◓ Structured Cabling</Link></li>
                  </ul>
                </div>
              </NavLin>  
            </NavItem>
            
            <NavItem>

              <NavLin to="/home" activeClassName="active" ><div className="dropdown">
                  <a className="btn btn-body dropdown-toggle" href="/home" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    Services
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li><Link className="dropdown-item" to="/FTTx">◓ FTTx Telecom Services</Link></li>
                    <li><Link className="dropdown-item" to="/InfrastructureIntegration">◓ Infrastructure Integration</Link></li>
                    <li><Link className="dropdown-item" to="/ManagedServices">◓ Managed Services</Link></li>
                    <li><Link className="dropdown-item" to="/SupportServices">◓ Support Services</Link></li>
                    <li><Link className="dropdown-item" to="/SystemIntegration">◓ System Integration</Link></li>
                    <li><Link className="dropdown-item" to="/ConsultingServices">◓ Consulting Services</Link></li>
                  </ul>
                </div>
              </NavLin>
            </NavItem>
            <NavItem>
              <NavLink to="/home" activeClassName="active" onClick={goToHomeAndScrollPartner}>Partners</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/home" activeClassName="active" onClick={goToHomeAndScrollContact}>Contact</NavLink>
            </NavItem>
            <NavItem>
              <NavLin ><Link  to="/news">News</Link></NavLin>
            </NavItem>
          </NavMenu>
          <NavBtn>
            {/* <NavBtnLink to="">Login</NavBtnLink> */}
            {/* <A href="http://ntcdigitalapp.com/Pages/Login.aspx">Login</A> */}
        <a href="http://ntcdigitalapp.com/Pages/Login.aspx" className="animated-button1">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Login
        </a>
          </NavBtn>
          {/* <NavBtn>
            <NavBtnLink to="/signin">Infrastructure Solutions</NavBtnLink>
          </NavBtn>
          <NavBtn>
            <NavBtnLink to="/">Business Solutions</NavBtnLink>
          </NavBtn> */}
        </NavbarContainer>

      </Nav>
    </>
  );
};
